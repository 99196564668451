import React from 'react'

import commitment from "../src/images/commitment.svg"
import budget from "../src/images/budget_friendly.svg"
import trusted from "../src/images/trusted.svg"
import team from "../src/images/team.svg"
import innovation from "../src/images/innovation.svg"
import reliable_data from "../src/images/reliable _data.svg"

function WhatMackOrex() {

    return (

        <div>

            <div className='container'>

                <div className="how_we">

                    <div className='boost'>
                        <p >Makes OrexTrade</p>
                    </div>

                    <h5> <span className='fw-bold'> What </span>
                        <span className='boost-span'>
                            <span className='fw-bold'>Makes OrexTrade </span> the Best Choice?
                        </span>

                    </h5>

                    <p className='with-our'>
                        The OrexTrade Advantage: Expertise, Innovation, and Global Trust.

                    </p>
                </div>


                <div className="features-icon-wrapper row  g-sm-12 ">

                    <div className="col-lg-4 col-sm-6 gy-5 text-center features-icon-box">
                        <div className="text-center mb-4">
                            <img src={commitment} alt="laptop charging" className='whatMackkOrex-img' />
                        </div>

                        <h5 className="mb-2">Commitment to Quality</h5>
                        <p className="with-our">The OrexTrade Team is dedicated to upholding their promises and delivering exceptional results.</p>
                    </div>

                    <div className="col-lg-4 col-sm-6 gy-5 text-center features-icon-box">
                        <div className="text-center mb-4">
                            <img src={budget} alt="transition up" className='whatMackkOrex-img' />
                        </div>
                        <h5 className="mb-2">Budget-Friendly</h5>
                        <p className="with-our">High-quality data reports at competitive prices.</p>
                    </div>

                    <div className="col-lg-4 col-sm-6 gy-5 text-center features-icon-box">
                        <div className="text-center mb-4">
                            <img src={trusted} alt="edit" className='whatMackkOrex-img' />
                        </div>

                        <h5 className="mb-2">Trusted by Thousands</h5>
                        <p className="with-our">8,000+ Clients Across the Globe.</p>
                    </div>

                    <div className="col-lg-4 col-sm-6 gy-5 text-center features-icon-box">
                        <div className="text-center mb-4">
                            <img src={team} alt="3d select solid" className='whatMackkOrex-img' />
                        </div>
                        <h5 className="mb-2">Expert Team</h5>
                        <p className="with-our">Skilled Experts with Extensive Experience.
                        </p>
                    </div>

                    <div className="col-lg-4 gy-5 col-sm-6 text-center features-icon-box">
                        <div className="text-center mb-4">
                            <img src={innovation} alt="user" className='whatMackkOrex-img' />
                        </div>
                        <h5 className="mb-2">Continuous Innovation</h5>
                        <p className="with-our">Regular Self-Checks to Maintain Data Quality and Usability.</p>
                    </div>

                    <div className="col-lg-4 gy-5 col-sm-6 text-center features-icon-box">
                        <div className="text-center mb-4">
                            <img src={reliable_data} alt="keyboard" className='whatMackkOrex-img' />
                        </div>
                        <h5 className="mb-2">Reliable Data</h5>
                        <p className="with-our">Complete, Accurate, and Verified Information
                            .</p>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default WhatMackOrex