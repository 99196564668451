import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';

import API_URL from './config/apiConfig';
import ROUTES from './config/routePath';


import AcounInfo from './AcounInfo';
import { property } from './App';

import regiImg from "./images/registra.jpg";


function Registraion() {

    // React.useEffect(() => {
    //     if (account) {

    //         account[0].style.border = "1px solid #696cff"

    //     }

    // }, [account]);

    const [errors, setErrors] = useState({});
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [pincode, setPincode] = useState("");

    const [country, setCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const [state, setSate] = useState("");
    const [states, setSates] = useState([]);
    const [selectedState, setSelectedState] = useState(null);

    const [city, setCity] = useState("");
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);



    const nav = useNavigate();
    const { account } = useContext(property);



    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
        setCity(selectedOption);
    };

    const handleStateChange = (selectedOption) => {

        setSate(selectedOption);
        setSelectedState(selectedOption);
        if (selectedOption && selectedOption.value) {

            try {
                axios.get(API_URL+"/cities/" + selectedOption.value, {
                    headers: {
                        "Accept": "application/json"
                    }
                }).then((response) => {
                    if (response.data.status === true) {
                        const options = response.data.data.map(city => ({
                            value: city.id,
                            label: city.name
                        }));


                        setCities(options);

                    } else {
                        console.log("Something went wrong");
                    }
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });

            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        }


    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validation()) {
            const data = {
                first_name: firstName,
                last_name: lastName,
                mobile: mobile,
                email: email,
                country: country.value,
                state: state.value,
                city: city.value,
                pincode: pincode,
                page: 1
            };
            try {
                axios.post(API_URL+"/member/store",
                    data,
                    {
                        headers: {
                            "Accept": "application/json"
                        }

                    }).then((response) => {

                        if (response.data.status == true) {
                            localStorage.setItem('member', JSON.stringify(response.data.member));
                            nav(ROUTES.PERSONAL);
                        } else {
                            const errors = {};
                            if (response.data.error.first_name) {
                                errors.firstName = response.data.error.first_name;
                            }
                            if (response.data.error.last_name) {
                                errors.lastName = response.data.error.last_name;
                            }
                            if (response.data.error.mobile) {
                                errors.mobile = response.data.error.mobile;
                            }
                            if (response.data.error.email) {
                                errors.email = response.data.error.email;
                            }
                            if (response.data.error.country) {
                                errors.country = response.data.error.country;
                            }

                            if (response.data.error.pincode) {
                                errors.pincode = response.data.error.pincode;
                      
                            }
                            setErrors(errors);
                            return Object.keys(errors).length > 0;
                        }
                    }).catch((error) => {
                        console.error('Error fetching data:', error);
                    });

            } catch (err) {
                console.error(err);
            }
        
        }
};
    
   const validation = () => {
    const errors = {};
        if(firstName == '' || firstName == null){
            errors.firstName = 'The first name field is required.';
        }
        if (lastName == '' || lastName == null) {
            errors.lastName = 'The last name field is required.';
        }
        if (email == '' || email == null) {
            errors.email = 'The email field is required.';
        }
        if (mobile == '' || mobile == null) {
            errors.mobile = 'The mobile field is required.';
        }
        if (country == '' || country == null) {
            errors.country = 'The country field is required.';
        }

        if (pincode == '' || pincode == null) {
            errors.pincode = 'The pincode field is required.';
        }
        setErrors(errors);
        return Object.keys(errors).length > 0;
    };

    const styleError = {
        display: 'block',
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(API_URL+"/countries", {
                    headers: {
                        "Accept": "application/json"
                    }
                }).then((response) => {
                    if (response.data.status === true) {
                        const options = response.data.data.map(country => ({
                            value: country.id,
                            label: country.name
                        }));
                        setCountries(options);
                    } else {
                        console.log("Something went wrong");
                    }
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchCountries();
    }, []);



    const handleCountryChange = (selectedOption) => {
        setSate("");
        setSelectedState("");
        setCountry(selectedOption);
        setSelectedCountry(selectedOption);

        if (selectedOption && selectedOption.value) {
            try {
                axios.get(API_URL+"/states/" + selectedOption.value, {
                    headers: {
                        "Accept": "application/json"
                    }
                }).then((response) => {
                    //console.log(response);
                    if (response.data.status === true) {
                        const options = response.data.data.map(state => ({
                            value: state.id,
                            label: state.name
                        }));
                        setSates(options);
                    } else {
                        console.log("Something went wrong");
                    }
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });

            } catch (error) {
                console.error('Error fetching states:', error);
            }
        }

    };

    useEffect(() => {
        try{
    const memberData = localStorage.getItem('member');
    const member = JSON.parse(memberData);
     if(member){
        setfirstName(member.first_name);
        setlastName(member.last_name);
        setEmail(member.email);
        setMobile(member.mobile);
        setPincode(member.pincode);
        
        const selectedOption = countries.find(option => option.value == member.country);
        if (selectedOption) {
            setSelectedCountry(selectedOption);
            setCountry(selectedOption);
            handleCountryChange(selectedOption);
            setSelectedState(member.state);
            setSelectedCity(member.city);
        } 
    } 
} catch(error) {
    console.error('Error fetching data:', error); 
}
},[countries]);

useEffect(() => {
    const memberData = localStorage.getItem('member');
    const member = JSON.parse(memberData);
    if(member){
        const selectedOption = states.find(option => option.value == member.state);
        if (selectedOption && selectedOption.value) {
            handleStateChange(selectedOption);
        }
    }
}, [states]);

useEffect(() => {
   
    const stateId = selectedState; 
    const stateToSelect = states.find(state => state.value == stateId);
    if(stateToSelect){
        setSelectedState(stateToSelect);
    }
},[states,selectedState]); 

useEffect(() => {      
        let localVar = JSON.parse(localStorage.getItem('member'));
        if(localVar){
        const cityToSelect = cities.find(city => city.value == localVar.city);
        if(cityToSelect){
        setSelectedCity(cityToSelect);
        setCity(cityToSelect);
    }
}   
}, [cities]); 

const [message, setMessage] = useState('');
useEffect(() => {
   var msg = localStorage.getItem('successMessage');
   if(msg){
    setMessage(msg);
    const timeoutId = setTimeout(() => {
        setMessage("");
        localStorage.removeItem('successMessage');
    }, 4000);
    return () => clearTimeout(timeoutId);
}
  }, []); 



    return (
        
        <div>
            
            <div className="main-regi">
           
                <div className="regi-menIMG">
                    <img src={regiImg} />
                </div>

               
                <div className="regi-form">
               
                    <AcounInfo />

                    {message && <div className="alert alert-success">{message}</div>}
                 
                    <form id="personal-info" onSubmit={handleSubmit}>
                   

                        <div className="row mt-5 px-5">
                       
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">

                                <label className="form-label">First Name*</label>
                                <input type="text" name="first_name" id="first_name" className="form-control" placeholder="Enter First Name" value={firstName} onChange={(e) => setfirstName(e.target.value)} />
                        
                                {errors.firstName && <div className="invalid-feedback" style={styleError}>{errors.firstName}</div>}
                               
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">

                                <label className="form-label">Last Name*</label>
                                <input type="text" name="last_name" id="last_name" className="form-control" placeholder="Enter Last Name" value={lastName} onChange={(e) => setlastName(e.target.value)} />
                                {errors.lastName && <div className="invalid-feedback" style={styleError}>{errors.lastName}</div>}

                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">

                            <label className="form-label">Mobile*</label>

                            
                            <input type="text" name="mobile" id="mobile" className="form-control" placeholder="Enter Mobile Number"  value={mobile} onChange={(e) => setMobile(e.target.value)}/>
                            {errors.mobile && <div className="invalid-feedback" style={styleError}>{errors.mobile}</div>}

                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">

                                <label className="form-label">Email*</label>
                                <input type="text" name="email" id="email" className="form-control" placeholder="Enter Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                                {errors.email && <div className="invalid-feedback" style={styleError}>{errors.email}</div>}
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            
                                <label className="form-label">Country*</label>
                                <Select
                                    options={countries}
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    placeholder="Select a country"
                                />
                               {errors.country && <div className="invalid-feedback" style={styleError}>{errors.country}</div>}
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label">State</label>
                                <Select
                                    options={states}
                                    value={selectedState}
                                    onChange={handleStateChange}
                                    placeholder="Select a state"
                                />

                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label">City</label>
                                <Select
                                    options={cities}
                                    value={selectedCity}
                                    onChange={handleCityChange}
                                    placeholder="Select a city"
                                />

                            </div>
                            
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label">Pincode*</label>
                                <input type="text" name="pincode" id="pincode" className="form-control" placeholder="Enter Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                {errors.pincode && <div className="invalid-feedback" style={styleError}>{errors.pincode}</div>}

                            </div>


                            <div className="col-12 d-flex justify-content-between  mt-3 ">
                            <Link to={"/"}>
                                    <button className='btn btn-light  back-home border border-1'>
                                        <i class="fa-solid fa-chevron-left mx-1 back-home"></i>

                                        Back To Home</button>
                                </Link>

                                <button type="submit" className=" btn-regi text-white border-0 regi-next mt-0">Next Step
                                    <i class="fa-solid fa-chevron-right mx-1"></i>

                                </button>                               
                            </div>

                            {/* <div>
                                <Link to={"/"}><button className='btn-regi text-white mt-5 border-0'>
                                    <MdKeyboardArrowLeft className='fs-2'></MdKeyboardArrowLeft>
                                    Back TO Home</button></Link>
                            </div>
                            <div>
                                <Link to={"/"}><button className='btn-regi text-white mt-5 border-0'>
                                    <MdKeyboardArrowLeft className='fs-2'></MdKeyboardArrowLeft>
                                    Back To Home</button></Link>
                            </div> */}

                        </div>

                    
                    </form>

                </div>

            </div>

        </div>

    )
}

export default Registraion
