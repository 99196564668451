import React, { useContext } from 'react'
import { property } from './App';
import orexlogo from "../src/images/orexlogo.svg"





function AcounInfo() {

    let { setaccount } = useContext(property)


    let acount = document.getElementsByClassName("aco-reg")

    //  console.log(acount)

    setaccount(acount)


    return (
        <div>
            <div className='registraion-orexlogo'>
                <img src={orexlogo} alt="" />
            </div>

            <div className='acount-regi'>

                <div className='aco-reg' id='acount' >

                    <div className='rego-ico'>
                        <i class="fa-solid fa-house-chimney a-home"></i>
                    </div>

                    <div className='rego-ico'>

                        <h6>Accoutn</h6>
                        <p>Account Details</p>

                    </div>

                    <div className='rego-ico'>
                        <i class="fa-solid fa-angle-right"></i>

                    </div>

                </div>

                <div className='aco-reg' id='acount-info' >

                    <div className='rego-ico'>
                        <i class="fa-solid fa-user a-home"></i>
                    </div>

                    <div className='rego-ico'>

                        <h6>Personal</h6>
                        <p>Enter Information</p>

                    </div>

                    <div className='rego-ico '>
                        <i class="fa-solid fa-angle-right"></i>
                    </div>

                </div>


                {/* <div className='aco-reg' id='acount-biling'>

                    <div className='rego-ico'>
                        <MdOutlinePayment className='a-home'></MdOutlinePayment>
                    </div>

                    <div className='rego-ico'>

                        <h6>Billing</h6>
                        <p>Payment Details</p>

                    </div>

                    <div className='rego-ico '>
                    <RiArrowRightWideFill className="fs-3" />
                    </div>

                </div> */}



            </div>
        </div>
    )
}

export default AcounInfo