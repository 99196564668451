import React , { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from "axios";

import API_URL from './config/apiConfig';
import contectImg from "../src/images/Inquiry-Now1.jpg"
import contPosImg from "../src/images/curve-circle.png"
import { FaPhoneVolume } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa6";

import errorMessages from './constant/errorMessages';


function Contact() {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        country_id: "",
        state_id: "",
        city_id: "",
        inquiry_message : ""
    });

    const [countryList, setCountryList] = useState([]);
    const [statesList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const [successmsg, setMessage] = useState("");
    const [errors, setErrors] = useState({});

    const styleError = {
        'display' : 'block'
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(validateForm()) {
            await axios.post(API_URL+'/inquiry/add',formData).then((response) => {
                if(response.status === 200) {
                    if(response.data.status === true) {
                        setMessage(response.data.message);
                        const frmdata = {
                            name: "",
                            email: "",
                            phone: "",
                            country_id: "",
                            state_id: "",
                            city_id: "",
                            inquiry_message : ""
                        }
                        setFormData(frmdata);
                        setSelectedCity(null);
                        setSelectedCountry(null);
                        setSelectedState(null);
                        setTimeout(() => {
                            setMessage("");
                        },3000);
                        
                    } else {                       
                        if(response.data.status === false) {
                            const newErrors = {};
                            if(response.data.error) {
                                if(response.data.error.name) {
                                    newErrors.name = response.data.error.name[0];
                                }
                                if(response.data.error.email) {
                                    newErrors.email = response.data.error.email[0];
                                }
                                if(response.data.error.phone) {
                                    newErrors.phone = response.data.error.phone[0];
                                }
                                if(response.data.error.country_id) {
                                    newErrors.country_id = response.data.error.country_id[0];
                                }
                                if(response.data.error.inquiry_message) {
                                    newErrors.message = response.data.error.inquiry_message[0];
                                }
                            }
                            setErrors(newErrors);
                        }
                        setMessage("Something Went Wrong!!");
                        setTimeout(() => {
                            setMessage("");
                        },3000);
                    }
                } else {
                    setMessage("Something Went Wrong!!");
                    setTimeout(() => {
                        setMessage("");
                    },3000);
                }
            }).catch((err) => {
                console.log("Error === ", err);
                setMessage("Something Went Wrong!!");
                setTimeout(() => {
                    setMessage("");
                },3000);
            }); 
        }
    }

    const validateForm = () => {
        setErrors({});
        let er = {};
        let flag = 0;
        if(formData.name === "") {
            flag = 1;
            er.name = errorMessages.NAME_REQUIRED;
        } 
        if(formData.email === "") {
            flag = 1;
            er.email = errorMessages.EMAIL_REQUIRED;
        }
        if(formData.phone === "") {
            flag = 1;
            er.phone = errorMessages.PHONE_REQUIRED;
        }
        if(formData.country_id === "") {
            flag = 1;
            er.country_id = errorMessages.COUNTRY_REQUIRED;
        }
        if(formData.inquiry_message === "") {
            flag = 1;
            er.inquiry_message = errorMessages.INQUIRY_MSG_REQUIRED;
        }

        setErrors(er);
        if(flag === 1) {
            return false;
        } else {
            return true;
        }
        
    }

    const handleChange = (e) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [e.target.name]: e.target.value
            }      
        })
    }

    const fetchCountries = async () => {
        await axios.get(API_URL+'/countries').then((response) => {
            if(response.status === 200) {
                if(response.data.status === true) {
                    const options = response.data.data.map(country => ({
                        value: country.id,
                        label: country.name
                    }));
                    setCountryList(options);
                }
            }
        }).catch((err) => {
            console.log("Error ========== ", err);
        })
    }

    const handleCountryChange = async (cval) => {
        setStateList([]);
        setCityList([]);
        const countryId = cval.value;
        setSelectedCountry(cval);
        setSelectedState(null);
        setSelectedCity(null);    
        setFormData((prevalue) => {
            return {
                ...prevalue,
                country_id: countryId
            }      
        });
        await axios.get(API_URL+'/states/'+countryId).then((response) => {
            if(response.status === 200) {
                if(response.data.status === true) {
                    const options = response.data.data.map(st => ({
                        value: st.id,
                        label: st.name
                    }));
                    setStateList(options);
                }
            }
        }).catch((err) => {
            console.log("Error ============= ", err);
        });
    }

    const handleStateChange = async (sval) => {
        setCityList([]);
        const stateId = sval.value;
        setSelectedState(sval);
        setSelectedCity(null);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                state_id: stateId
            }      
        });
        await axios.get(API_URL+'/cities/'+stateId).then((response) => {
            if(response.status === 200) {
                if(response.data.status === true) {
                    const options = response.data.data.map(ct => ({
                        value: ct.id,
                        label: ct.name
                    }));
                    setCityList(options);
                }
            }
        }).catch((err) => {
            console.log("Error ============= ", err);
        });
    }

    const handleCityChange = async (ctval) => {
        const cityId = ctval.value;
        setSelectedCity(ctval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                city_id: cityId
            }      
        });
    }

    useEffect(() => {
        fetchCountries();
    },[]);

    return (

        <div>

            <section id="landingContact" className="section-py bg-body landing-contact">
                <div>
                    <div className="how_we">
                        <div className='boost'>
                            <p >Inquiry Now</p>
                        </div>
                        <h5> <span className='fw-bold'></span>
                            <span className='boost-span'>
                                <span className='fw-bold'>Inquiry </span>Now
                            </span>
                        </h5>

                        <p className='with-our'>
                            Any question or remark? just write us a message.
                        </p>

                    </div>


                    <div className="row g-6 contect-section">

                        <div className="col-lg-5 contect-imges">
                            <div className="contact-img-box position-relative border p-2 h-100">
                                <img src={contPosImg} alt="contact border" className="contact-border-img position-absolute d-none d-lg-block scaleX-n1-rtl" />

                                <img src={contectImg} alt="contact customer service" className="contact-img w-100 scaleX-n1-rtl" />

                                <div className="p-4 pb-2 mt-2">

                                    <div className="row g-4">

                                        <div className="col-md-6 col-lg-12 col-xl-6">
                                            <div className="d-flex align-items-center">

                                                <i class="fa-regular fa-envelope contect-icon fs-5 "></i>
                                                <div>
                                                    <p className="mb-0">Email</p>
                                                    <h6 className="mb-0">info@orextrade.com</h6>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-6 col-lg-12 col-xl-6">
                                            <div className="d-flex align-items-center">
                                            <i class="fa-solid fa-phone-volume fs-5 contect-icon ontect-icon-phone"></i>
                                                <div>
                                                    <p className="mb-0">Phone</p>
                                                    <h6 className="mb-0">+1234 568 963</h6>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-7">
                            <div className="card h-100 border-0 shadow ">
                                <div className="card-body p-5 ">
                                    <h4 className="mb-2">Send a message</h4>
                                    <p className="mb-6">
                                        Complete this form to learn more about our services or share your requirements.
                                        <br className="d-none d-lg-block" />
                                        We’ll analyse your request and get back to you shortly.

                                    </p>

                                    <form onSubmit={handleSubmit}>

                                        <div className="row g-4">

                                            <div className="col-md-6">
                                                <label className="form-label inquiry-text " for="contact-form-fullname">Full Name*</label>
                                                <input type="text" className="form-control inp-font-size" id="contact-form-fullname" placeholder="Enter Full Name" onChange={handleChange} name="name" value={formData.name} />
                                                {errors.name && <div className="invalid-feedback" style={styleError}>{errors.name}</div>}
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label inquiry-text " for="contact-form-email">Email*</label>
                                                <input type="email" id="contact-form-email " className="form-control inp-font-size" placeholder="Enter Email" onChange={handleChange} name="email" value={formData.email} />
                                                {errors.email && <div className="invalid-feedback" style={styleError}>{errors.email}</div>}
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label inquiry-text " for="contact-form-phone">Phone*</label>
                                                <input type="text" id="contact-form-phone" className="form-control inquiry-phone inp-font-size" placeholder="Enter Phone" name="phone" onChange={handleChange} value={formData.phone} />
                                                {errors.phone && <div className="invalid-feedback" style={styleError}>{errors.phone}</div>}
                                            </div>

                                            <div className='col-md-6 pb-0'>
                                                <label className='pt-2 inquiry-text'> Contry*</label>
                                                <Select
                                                    options={countryList}
                                                    value={selectedCountry}
                                                    onChange={handleCountryChange}
                                                    placeholder="Select a country"
                                                  
                                                />
                                                    {errors.country_id && <div className="invalid-feedback" style={styleError}>{errors.country_id}</div>}

                                            </div>

                                            <div className='col-md-6 pb-0'>
                                                <label className='inquiry-text'> State</label>
                                                <Select
                                                    options={statesList}
                                                    value={selectedState}
                                                    onChange={handleStateChange}
                                                    placeholder="Select a State"
                                                />

                                            </div>

                                            <div className='col-md-6 pb-0'>
                                                <label className=' inquiry-text'> City</label>
                                                <Select
                                                    options={cityList}
                                                    value={selectedCity}
                                                    onChange={handleCityChange}
                                                    placeholder="Select a City"
                                                />
                                               
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label inquiry-text" for="contact-form-message">Message*</label>
                                                <textarea id="contact-form-message" className="form-control" rows="3" placeholder="Write a message" onChange={handleChange} name="inquiry_message" value={formData.inquiry_message} ></textarea>
                                                {errors.inquiry_message && <div className="invalid-feedback" style={styleError}>{errors.inquiry_message}</div>}
                                            </div>

                                            <div className="col-12">
                                                <button type="submit" className="btn-get">Send inquiry</button>
                                            </div>
                                        </div>
                                    </form>
                                    { successmsg && <div className="alert alert-success mt-3">{successmsg}</div>}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

        </div>

    )
}

export default Contact
