import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import review from "../src/images/review-img.png"

function Coustomers() {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className='container-fluid customer-section'>

            <div className="how_we">

                <div className='boost'>
                    <p >Our Customer's say</p>
                </div>

                <h5> <span className='fw-bold'> What </span>
                    <span className='boost-span'>
                        <span className='fw-bold'>Our Customer's </span> say
                    </span> {" "}about Us

                </h5>
                <p className='with-our'>
                    See what our customers have to say about their experience.

                </p>

            </div>


            <div style={{ position: "relative" }} className="row justify-content-center mt-5">

                <Carousel responsive={responsive} className="col-7 customer-card  ">



                    <div className=" card border-0 shadow me-5 " >

                        <div className="customor-about">

                            <p className="card-text text-center w-75">Some quick example text to build on the card title and make up the bulk of the card's content.Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                            <img className=" rounded-circle " src={review} alt="Card image cap" />
                            <h6 className="pt-3">jadav dharmesh</h6>

                            <h3 className='m-0 p-0'>OrexTrade</h3>

                        </div>

                    </div>


                    <div className=" card border-0 shadow ms-5 " >

                        <div className="customor-about">

                            <p className="card-text text-center w-75">Some quick example text to build on the card title and make up the bulk of the card's content.Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                            <img className=" rounded-circle " src={review} alt="Card image cap" />
                            <h6 className="pt-3">jadav dharmesh</h6>

                            <h3 className='m-0 p-0'>OrexTrade</h3>

                        </div>

                    </div>


                </Carousel>
            </div>

            {/* 
            <div className='row'>

                <div className="card text-center mb-5" >

                    <img className="card-img-top" src="" alt="Card image cap" />
                    <div className="card-body">
                        <h5 className="card-title">Card title</h5>
                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>

                </div>

            </div> */}

        </div>
    )
}

export default Coustomers