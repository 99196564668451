import React, { useContext } from 'react'
import AcounInfo from './AcounInfo';
import regiImg from "../src/images/regi-img.png"
import { useNavigate } from 'react-router-dom';
import { property } from './App';



function AcountPayment() {

    const nav = useNavigate();

    const { account } = useContext(property);

    React.useEffect(() => {
        if (account) {

            account[2].style.border = "1px solid #696cff"

        }

    }, [account]);

    return (

        <div>

            <div className="main-regi container-fluid">

                <div className="regi-girl reg-bag">
                    <img src={regiImg} />
                </div>

                <div className="regi-form">

                    <AcounInfo />

                    {/* <form>

                        <div id="billingLinksValidation" className="content fv-plugins-bootstrap5 fv-plugins-framework active dstepper-block container mt-5 ">

                            <div className="content-header text-center">
                                <h4 className="mb-0">Select Plan</h4>
                                <p className="mb-0">Select plan as per your requirement</p>
                            </div>


                            <div className="row  text-center mt-5">

                                <div className="col-md">

                                    <div className="form-check custom-option custom-option-icon checked">
                                        <label className="form-check-label custom-option-content" for="basicOption">
                                            <span className="custom-option-body">
                                                <span className="d-block mb-2 h5">Basic</span>
                                                <span>A simple start for start ups &amp; Students</span>
                                                <span className="d-flex justify-content-center mt-2">
                                                    <sup className="text-primary h6 fw-normal pt-2 mb-0">$</sup>
                                                    <span className="fw-medium h3 text-primary mb-0">0</span>
                                                    <sub className="h6 fw-normal mt-3 mb-0 text-muted">/month</sub>
                                                </span>
                                            </span>
                                            <input name="customRadioIcon" className="form-check-input" type="radio" value="" id="basicOption" />
                                        </label>
                                    </div>

                                </div>

                                <div className="col-md">

                                    <div className="form-check custom-option custom-option-icon border ">
                                        <label className="form-check-label custom-option-content" for="standardOption">
                                            <span className="custom-option-body">
                                                <span className="d-block mb-2 h5">Standard</span>
                                                <span>For small to medium businesses</span>
                                                <span className="d-flex justify-content-center mt-2">
                                                    <sup className="text-primary h6 fw-normal pt-2 mb-0">$</sup>
                                                    <span className="fw-medium h3 text-primary mb-0">99</span>
                                                    <sub className="h6 fw-normal mt-3 mb-0 text-muted">/month</sub>
                                                </span>
                                            </span>
                                            <input name="customRadioIcon" className="form-check-input" type="radio" value="" id="basicOption" />
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md">
                                    <div className="form-check custom-option custom-option-icon">
                                        <label className="form-check-label custom-option-content" for="enterpriseOption">
                                            <span className="custom-option-body">
                                                <span className="d-block mb-2 h5">Enterprise</span>
                                                <span>Solution for enterprise &amp; organizations</span>
                                                <span className="d-flex justify-content-center mt-2">
                                                    <sup className="text-primary h6 fw-normal pt-2 mb-0">$</sup>
                                                    <span className="fw-medium h3 text-primary mb-0">499</span>
                                                    <sub className="h6 fw-normal mt-3 mb-0 text-muted">/year</sub>
                                                </span>
                                            </span>
                                            <input name="customRadioIcon" className="form-check-input" type="radio" value="" id="enterpriseOption" />
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="content-header mt-5">
                                <h4 className="mb-0">Payment Information</h4>
                                <p className="mb-0">Enter your card information</p>
                            </div>


                            <div className="row mt-5 my-5 ">

                                <div className="col-md-12 fv-plugins-icon-container">
                                    <label className="form-label w-100" for="multiStepsCard">Card Number</label>
                                    <div className="input-group input-group-merge has-validation">
                                        <input id="multiStepsCard" className="form-control multi-steps-card" name="multiStepsCard" type="text" placeholder="1356 3215 6548 7898" aria-describedby="multiStepsCardImg" />
                                        <span className="input-group-text cursor-pointer" id="multiStepsCardImg"><span className="card-type"></span></span>
                                    </div><div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                </div>

                                <div className="col-md-5 mt-5 my-3">
                                    <label className="form-label" for="multiStepsName">Name On Card</label>
                                    <input type="text" id="multiStepsName" className="form-control" name="multiStepsName" placeholder="John Doe" />
                                </div>

                                <div className="col-6 col-md-4 mt-5 my-3">
                                    <label className="form-label" for="multiStepsExDate">Expiry Date</label>
                                    <input type="text" id="multiStepsExDate" className="form-control multi-steps-exp-date" name="multiStepsExDate" placeholder="MM/YY" />
                                </div>

                                <div className="col-6 col-md-3 mt-5 my-3">
                                    <label className="form-label" for="multiStepsCvv">CVV Code</label>
                                    <div className="input-group input-group-merge">
                                        <input type="text" id="multiStepsCvv" className="form-control multi-steps-cvv" name="multiStepsCvv" maxlength="3" placeholder="654" />
                                        <span className="input-group-text cursor-pointer" id="multiStepsCvvHelp"><i className="bx bx-help-circle text-muted" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Card Verification Value" data-bs-original-title="Card Verification Value"></i></span>
                                    </div>
                                </div>

                                <div className="col-12 d-flex justify-content-between  mt-3 ">

                                    <button className="btn btn-label-secondary btn-prev">
                                        Prev
                                    </button>
                                    <button className="btn btn-primary btn-next">Next </button>
                                </div>

                            </div>

                        </div>

                    </form> */}

                </div>

            </div>

        </div>
    )
}

export default AcountPayment