
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Website from './Website';
import Registraion from './Registraion';
import AcountPersonal from './AcountPersonal';
import AcountPayment from './AcountPayment';
import { createContext, useState } from 'react';
import About from './About';



export let property = createContext("")

function App() {

  let [account, setaccount] = useState("");

  return (
    <BrowserRouter>     
    
      <property.Provider value={{account,setaccount}}>
        <Routes>

          <Route exact path='/' element={<Website />}></Route>
          <Route exact path='/registration' element={<Registraion/>}></Route>
          <Route exact path='/personal' element={<AcountPersonal/>}></Route>
          <Route exact path='/payment' element={<AcountPayment/>}></Route>
          <Route exact path='/account' element={<Registraion/>}></Route>
          <Route exact path='/about' element={<About/>}></Route>
      
        </Routes>

      </property.Provider>
    </BrowserRouter>

  );
}

export default App;
