import React from 'react'
import faq from "../src/images/faq1.png"

function Faq() {

    return (

        <div>

            <div id="landingFAQ" >

                <div className="container-fluid faq-section pb-4">

                    <div className="how_we">

                        <div className='boost'>
                            <p >FAQ</p>
                        </div>

                        <h5> <span className='fw-bold'> Frequently</span>
                            <span className='boost-span'>{" "}
                                <span className='fw-bold'>Asked </span> Questions ?
                            </span>

                        </h5>


                    </div>


                    <div className="row align-items-center justify-content-center">

                        <div className="col-lg-4 row">
                            <p className='with-our set-faq'>
                                Browse through these FAQs to find answers to commonly asked questions.
                            </p>
                            <div className="text-center faq-img">
                                <img src={faq} alt="faq boy with logos" className="faq-image w-100 " />
                            </div>

                        </div>



                        <div className="col-lg-7 ">

                            <div className="accordion" id="accordionExample">

                                <div className="card accordion-item border">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="false" aria-controls="accordionOne">
                                            <span className='with-our set-faq'>  What is an HS Code? </span>
                                        </button>
                                    </h2>

                                    <div id="accordionOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample" >

                                        <div className="accordion-body">
                                        An HS Code is a classification system developed by the World Customs Organization (WCO) to define internationally traded goods. To import or export products, an HS code is required to align with the import country's tariff schedule. At OrexTrade, we ensure accurate HS code assignment for smooth global trade operations.

                                        </div>

                                    </div>
                                </div>

                                <div className="card accordion-item mt-2">

                                    <h2 className="accordion-header" id="headingTwo">
                                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionTwo" aria-expanded="false" aria-controls="accordionTwo">
                                            <span className='with-our set-faq'>How to get leads from global trade data?</span>
                                        </button>
                                    </h2>

                                    <div id="accordionTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        To get leads from global trade data, OrexTrade's Sales Lead Generator identifies potential buyers worldwide for your specific commodities or services. We provide company names, contact details, and insights to help you fill your sales pipeline effectively.

                                        </div>
                                    </div>
                                </div>

                                <div className="card accordion-item active mt-2">

                                    <h2 className="accordion-header" id="headingThree">
                                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionThree" aria-expanded="false" aria-controls="accordionThree">
                                            <span className='with-our set-faq'>  Can I find potential buyers for my export product using OrexTrade?</span>
                                        </button>
                                    </h2>

                                    <div id="accordionThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
                                        <div className="accordion-body">
                                        Yes, with OrexTrade, you can easily find potential buyers for your export products. We offer access to detailed buyer profiles, purchase habits, and market insights across the globe, helping you connect with the right partners and expand your international reach.

                                        </div>
                                    </div>
                                </div>

                                <div className="card accordion-item mt-2">

                                    <h2 className="accordion-header" id="headingFour">
                                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFour" aria-expanded="false" aria-controls="accordionFour">
                                            <span className='with-our set-faq'> Is it easy to understand? </span>
                                        </button>
                                    </h2>

                                    <div id="accordionFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        Yes, using OrexTrade is incredibly easy. Our user-friendly, straightforward analytical tools make navigating global trade data as simple as 1, 2, 3. With OrexTrade, you'll quickly find the insights you need to grow your business.
                                        </div>
                                    </div>
                                </div>

                                <div className="card accordion-item mt-2">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFive" aria-expanded="false" aria-controls="accordionFive">
                                            <span className='with-our set-faq'> Is it based on the latest Export-Import data? </span>
                                        </button>
                                    </h2>
                                    <div id="accordionFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        Yes, OrexTrade is based on the latest Buyer -Supplier data. We provide up-to-date, analysed information from official sources like WTO, Intracen, and Trading Economics, along with proprietary AI-driven processes. Our data is trusted by exporters worldwide, making OrexTrade a powerful tool for expanding your global trade operations.
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Faq