import React from 'react'
import world from "../src/images/world2.svg"
import calander from "../src/images/calendar.svg"
import ship from "../src/images/ship.svg"
import trade from "../src/images/trade_product.svg"
import distribution from "../src/images/distribution.svg"



function WhatDatas() {
  return (
    <div>

      <div className="container-fluid text-center what-data">

        <div className="how_we">

          <div className='boost'>
            <p >Data Access</p>
          </div>

          <h5> <span className='fw-bold'> What </span>
            <span className='boost-span'>
              <span className='fw-bold'>Type of Data </span> Do You
            </span> {" "} Access?

          </h5>

          <p className='with-our'>
            Explore Global Buyers, Suppliers, and Market Insights for Informed Decisions.

          </p>
        </div>


        <div className='row justify-content-center what-datacard'>

          <div className='whatdata-card col-xl-4 col-xxl-2   '>

            <div className=' card p-2 shadow  whatcard-border '>

              <div className=' d-flex justify-content-center align-items-center'>


                <div className='whatdata_icon'>


                  <img src={world} alt="" />
                </div>

              </div>

              <div>

                <p className='with-our'>
                  Global Database of Authentic Buyers and Suppliers

                </p>

              </div>

            </div>
          </div>


          <div className=' whatdata-card   col-xl-4 col-xxl-2    ' >

            <div className='card p-2 shadow   whatcard-border'>

              <div className=' d-flex justify-content-center align-items-center'>

                <div className='whatdata_icon'>
                  <img src={calander} alt="" />
                </div>


              </div>

              {/* <div>
                <img src={calander} alt="laptop" className="" />
              </div> */}

              <div>
                <p className="with-our">
                Current High-Demand Trade Products for Buyers & Suppliers
                </p>
              </div>

            </div>

          </div>


          <div className='whatdata-card   col-xl-4 col-xxl-2   '>

            <div className='card p-2 shadow  whatcard-border'>

              <div className=' d-flex justify-content-center align-items-center'>

                <div className='whatdata_icon'>
                  <img src={ship} alt="" />

                </div>

              </div>
              {/*               
              <div>
                <img src={ship} alt="laptop" className="" />
              </div> */}

              <div>
                <p className="with-our">
                  Active Ports and Operating Countries
                </p>
              </div>

            </div>
          </div>

          <div className='whatdata-card   col-xl-4 col-xxl-2   '>

            <div className='card p-2 shadow  whatcard-border'>

              <div className='d-flex justify-content-center align-items-center'>

                <div className='whatdata_icon'>
                  <img src={trade} alt="" />

                </div>


              </div>


              <div>
                <p className="with-our ">
                  Trade Product Pricing Details
                </p>
              </div>

            </div>

          </div>


          <div className='whatdata-card col-xl-4 col-xxl-2 '>

            <div className='card p-2 shadow whatcard-border'>

              <div className=' d-flex justify-content-center align-items-center'>

                <div className='whatdata_icon'>
                  <img src={distribution} alt="" />

                </div>

              </div>

              {/* <div>
                <img src={distribution} alt="laptop" className="" />
              </div> */}

              <div>
                <p className="with-our">
                  Applicable Duties on Trade Products
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatDatas