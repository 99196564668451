import React, { useContext,useEffect,useState} from 'react'
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

import API_URL from './config/apiConfig';
import ROUTES from './config/routePath';

import AcounInfo from './AcounInfo';
import regiImg from "../src/images/regi-img.png"

import { property } from './App';



function AcountPersonal() {
    const nav = useNavigate();
    const memberId = localStorage.getItem('member');
    if(memberId == null || memberId == ""){
        nav(ROUTES.REGISTRATION);
    }

    const { account } = useContext(property);

    React.useEffect(() => {
        if (account) {

            account[1].style.border = "1px solid #696cff"

        }

    }, [account]);

    const handlePrevClick = () => {
        nav(ROUTES.REGISTRATION);
      };

  
    const [errors, setErrors] = useState({});
    const [industries, setIndustries] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [selectedShipmentMode, setSelectedShipmentMode] = useState(null);
    const [selectedCompanySize, setSelectedCompanySize] = useState(null);
    const [companyName,setcompanyName] = useState("");
    const [industry,setIndustry] = useState("");
  
    useEffect(() => {
     
        axios.get(API_URL+"/industry", {
            headers: {
                "Accept": "application/json"
            }
        }).then((response) => {
            if (response.data.status === true) {
                const options = response.data.data.map(industry => ({
                    value: industry.id,
                    label: industry.industy_name
                }));
                setIndustries(options);
            } else {
                console.log("Something went wrong");
            }
        }).catch((error) => {
            console.log('Error fetching data:', error);
        });
    
    }, []);

    const handleIndustryChange = (selectedOption) => {
        setSelectedIndustry(selectedOption);
        setIndustry(selectedOption);
    }

    const handleSelectChangeCompanySize = (event) => {
        setSelectedCompanySize(event.target.value);
    }

    const handleSelectChangeShipmentMode = (event)=>{
        setSelectedShipmentMode(event.target.value);
    }   

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if(!validation()){
        try {
        const memberData = localStorage.getItem('member');
        const member = JSON.parse(memberData);
        // console.log(member);
        if(member){
     
        await axios.post(API_URL+"/member/store",{
            company_name:companyName,
            company_size:selectedCompanySize,
            industry:industry.value,
            shipment_mode:selectedShipmentMode,
            first_name:member.first_name,
            last_name:member.last_name,
            mobile:member.mobile,
            email:member.email,
            country:member.country,
            state:member.state,
            city:member.city,
            pincode:member.pincode,
            page:2
            
        }, {
            headers: {
                "Accept": "application/json"
            }
        }).then((response) => {
            if (response.data.status == true) {
                localStorage.removeItem('member');
                localStorage.setItem('successMessage', response.data.message);
                nav(ROUTES.REGISTRATION);
            } else {
                const errors = {};
                if(response.data.error.company_name){
                    errors.companyName = response.data.error.company_name;
                }
                if(response.data.error.company_size){
                    errors.company_size = response.data.error.company_size;
                }
                
                if(response.data.error.industry){
                    errors.industry = response.data.error.industry;
                }

                if(response.data.error.shipment_mode){
                    errors.shipment_mode = response.data.error.shipment_mode;
                }
                
                console.log("Something went wrong");
                setErrors(errors);
                return Object.keys(errors).length > 0;
               
            }
        }).catch((error) => {
            console.log('Error fetching data:', error);
        });

        }
    } catch (err) {
        console.log(err);
    }
       
}else{
    console.log("Something went wrong"); 
}
    }

    const validation = () => {
        const errors = {};
            if(companyName == '' || companyName == null){
                errors.companyName = 'The company name field is required.';
            }
            if(selectedCompanySize == '' || selectedCompanySize == null){
                    errors.company_size = 'The company size field is required.';
            }
            if(industry == '' || industry == null){
                errors.industry = 'The industry field is required.';
            }
            if(selectedShipmentMode == '' || selectedShipmentMode == null){
                errors.shipment_mode = 'The shipment mode field is required.';
            }
           

            setErrors(errors);
            return Object.keys(errors).length > 0;
        };

    const styleError = {
        display: 'block',
    };
    return (
        <div>

            <div className="main-regi container-fluid ">

                <div className="regi-girl reg-bag">
                    <img src={regiImg} alt="multi-steps" data-app-dark-img="illustrations/create-account-dark.png" data-app-light-img="illustrations/create-account-light.png" />
                </div>

                <div className="regi-form">

                    <AcounInfo />

                    <form onSubmit={handleSubmit}>

                        <div className="row mt-5 px-5">

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label">Company Name*</label>
                                <input type="text" id="company_name" name="company_name" className="form-control" placeholder="Enter Company Name" value={companyName} onChange={(e) => setcompanyName(e.target.value)}/>
                                {errors.companyName && <div className="invalid-feedback" style={styleError}>{errors.companyName}</div>}
                            </div>

                            <div className="col-sm-6 mt-3 ">
                                <label className="form-label">Company Size*</label>
                                <div className="position-relative">

                                    <select onChange={handleSelectChangeCompanySize} name="company_size" id="company_size" className="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="multiStepsState" tabIndex="-1" aria-hidden="true">

                                        <option value="" data-select2-id="2">Select Company Size*</option>
                                        <option value="1-10">1-10 employees</option>
                                        <option value="11-50">11-50 employees</option>
                                        <option value="51-200">51-200 employees</option>
                                        <option value="201-500">201-500 employees</option>
                                        <option value="501-1000">501-1000 employees</option>
                                        <option value="1000-5000">1000-5000 employees</option>
                                        <option value="5001-10000">5001-10,000 employees</option>
                                        <option value="10000-1">10,00+1 employees</option>
                                    </select>
                                    {errors.company_size && <div className="invalid-feedback" style={styleError}>{errors.company_size}</div>}
                                </div>
                            </div>

                            <div className="col-sm-6 mt-3 ">
                                <label className="form-label">Industry*</label>
                                <div className="position-relative">

                                <Select
                                    options={industries}
                                    value={selectedIndustry}
                                    onChange={handleIndustryChange}
                                    placeholder="Select Industry"
                                />
                                {errors.industry && <div className="invalid-feedback" style={styleError}>{errors.industry}</div>}
                                </div>
                            </div>

                            <div className="col-sm-6 mt-3 ">
                                <label className="form-label">Shipment Mode*</label>
                                <div className="position-relative">

                                    <select onChange={handleSelectChangeShipmentMode}  name="shipment_mode" id="shipment_mode" className="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="multiStepsState" tabIndex="-1" aria-hidden="true">

                                        <option value="" data-select2-id="2">Select Shipment Mode</option>
                                        <option value="import">Import</option>
                                        <option value="export">Export</option>
                                        <option value="both">Both</option>
                                        
                                    </select>
                                    {errors.shipment_mode && <div className="invalid-feedback" style={styleError}>{errors.shipment_mode}</div>}
                                </div>
                            </div>


                            <div className="col-12 d-flex justify-content-between  mt-3 ">

                                <button onClick={handlePrevClick} className="btn-regi text-white border-0">
                                    Prev
                                </button>
                                <button type="submit" className="btn-regi text-white border-0 regi-next" >Save
                                <i class="fa-solid fa-chevron-right mx-1"></i>
                                </button>
                            </div>

                        </div>
                    </form>

                </div>


            </div>
        </div>
    )
}

export default AcountPersonal